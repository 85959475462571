@font-face {
  font-family: Bango;
  src: url("./../fonts/BangoPro.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Muli;
  src: url("./../fonts/Muli-SemiBold.ttf");
  font-weight: 500;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');


@mixin backgroundimage() {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
};
@mixin marginCenter($width) {
  max-width: #{$width}px;
  margin-left: auto;
  margin-right: auto;
};
@mixin absoluteFull() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
};
@mixin flexWidth($width) {
  flex: 0 0 #{$width}#{'%'};
  max-width: #{$width}#{'%'};
};

$primary-font: 'Bango', sans-serif;
$comfortaa-font: 'Comfortaa', cursive;
$muli-font: 'Muli', cursive;
$transition_c: 1s cubic-bezier(0.23, 1, 0.32, 1);
$transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
$container_width: 1260px;
$white_color: #fff;
$black_color: #0d0d0d;
$light_fonts: 300;
$mbold_fonts: 500;
$bold_fonts: 700;
$black_fonts: 900;

$primary_color: #00205B;
$pink_color: #fff7f0;
$orange_color: #FA6400;
$lblue_color: #586274;
$vlblue_color: #96A1B6;

html {
  height: 100%;
}

body{
  font-family: $primary_font; font-weight: 500; line-height: 1; color: $primary_color; font-size: 16px;
  background-image: url("./../images/page_bg.jpg"); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  overflow-x: hidden;
  #content {
    display: flex;
    flex-direction: column;
    height: 100%;
    #main_content {
      flex: 1 0 auto;
    }
    footer {
      flex-shrink: 0;
    }
  }
}

html.no-scroll, body.no-scroll{overflow: hidden; height: 100%; }
a, a:link, a:visited, a:hover, a:active{text-decoration: none; color: $primary_color; line-height: 1.5; }
a:hover{text-decoration: underline};
p{margin-bottom: 1.5rem}
p:last-child{ margin-bottom: 0;}
:focus{outline: none}

textarea, button, input[type="text"], input[type="button"], input[type="email"], input[type="submit"], input[type="tel"], input[type="number"], input[type="password"],
[type=reset], [type=submit]
{
  -webkit-appearance: none; border-radius: 0; outline: none;
  &:focus{
    outline: none;
  }
}

.button_default{
  border: 10px solid transparent;
  border-image-source: url("./../images/button_default.png");
  border-image-slice: 20 fill;
  background-color: transparent;
  cursor: pointer;
  width: 120px;
  height: 40px;
  font-weight: 500; font-size: 18px; text-transform: uppercase; color: #fff;
  text-align: center; text-shadow: 1px 1px 5px #1C4851;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.button_glass{
  border: 10px solid transparent;
  border-image-source: url("./../images/button_glass.png");
  border-image-slice: 20 fill;
  background-color: transparent;
  cursor: pointer;
  width: inherit;
  margin: 0 5px;
  height: 45px;
  font-weight: 500; font-size: 18px; text-transform: uppercase; color: #fff;
  text-align: center; text-shadow: 1px 1px 5px #1C4851;
}

.container{
  max-width: $container_width; padding-left: 60px; padding-right: 60px;
  &.left_container{
    padding-right: 0; margin-left: calc((100% - #{$container_width}) / 2); max-width: 100%;
  }

}
.large_container{max-width: 1460px; }

.beta_ribbon{
    img{
      position: absolute;
      top: 68px;
      width: 180px;
      right: 0px;
    }
  } 
  .login{
    img{
      position: absolute;
      top: 68px;
      width: 18vw;
      max-width: 150px;
      right: 0px;
      z-index: -1;
    }
}

#header_menu_section{
  background-image: url("./../images/header_bg.png"); height: 90px; background-size: auto 100%; background-repeat: repeat-x;
  position: absolute; width: 100%;
  .header_menu_content{
    position: relative;
    .inner_content{
      height: 65px; display: flex; align-items: center; position: relative;
      .site_logo{
        height: 58px; margin-right: 100px;
        margin-top: 7px;
        img{
          height: 100%;
        }
      }
      #bookmarkme{
        img{
          height: 130%;
          margin-top: -10px;
        }
      }
      #fullscreenbutton{
        img{
          height: 100%;
        }
      }
      .right_col{
        position: absolute; height: 100%; right: 0;
        .sound, .loginout{
          display: inline-block;
          img{
            height: 100%;
          }
        }
        .sound{
          height: 42px; margin-top: 15px; position: relative; cursor: pointer;
          .on{
            opacity: 1;
          }
          .off{
            position: absolute; opacity: 0; left: 0; top: 0;
          }
          &.muted{
            .on{
              opacity: 0;
            }
            .off{
              opacity: 1;
            }
          }
        }
        .loginout{
          margin-top: 15px; position: relative;
        }
        .playername{
          font-size: 14px; color: #fff; text-shadow: 1px 1px 2px #1C4851;
        }       
      }
    }
  }
}
.fullpage{
  background-image: url("./../images/page_bg.jpg"); background-size: cover; background-position: top center; margin: 0 auto;
  max-width: none;
  position: relative;
}
#gamepage, #errorpage{
  max-width: 100%;
}

#homepage{
  height: 100%;
  .fullpage_bg{
    height: 100%;
    background-image: url("./../images/home_bg.jpg"); background-size: cover; background-position: top center;
    background-repeat: repeat-x; display: flex; align-items: flex-end;
  }
  .homepage_content{
    margin-left: 50%; text-align: center; 
    margin-top: 100px;
    margin-bottom: 85px;
    &_container{
      max-width: 380px;
      .mobile_only{
        display: none;
      }
    }
    &_img{
      width: 340px; max-width: 95%; margin-bottom: 10px;
    }
    &_text{
      font-size: 26px; color: #fff; line-height: (33px / 26px); text-shadow: 3px 3px 5px #032825;
      margin-bottom: 10px;
      span{
        color: #FCA900;
      }
    }
    &_login{
      display: block; position: relative;
      img{
        width: 100%;
      }
      .text{
        position: absolute; color: #A8DDD9; font-size: 17px; left: 0; right: 0; width: 100%; top: 13px;
        text-shadow: 3px 3px 5px #032825;
      }
    }
    &_loginbtn{
      margin-left: auto; margin-right: auto; display: block; position: relative;
      button{
        width: 180px;
        height: 60px;
        font-weight: 500; font-size: 30px; text-transform: uppercase; color: #fff;
      }     
    }
  }
}

#gamepage, #errorpage{
  .fullpage_bg{
    display: flex; 
    align-items: baseline; 
    padding-bottom: 40px;
    position: relative;
    margin-top: 110px;
  }
  #game_frame{
    width: 80vw;
    height: 40vw;
    max-width: 1536px;
    max-height: 768px;
    margin-left: auto; 
    margin-right: auto;
    border: 5px solid rgba(64, 165, 168, 1);
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .errorpage_content{
    max-width: 650px; margin-left: auto; margin-right: auto; margin-top: 150px; position: relative;
    .error_bg{
      width: 100%;
    }
    .desktop_only{
      top: 10px;
    }
    .mobile_only{
      top: 280px;
      display: none;
    }
    .error_content{
      position: absolute;
      right: 80px;
      text-align: center; width: 200px;
      .error_oops{
        width: 100%;
      }
      .text{
        font-size: 20px; color: #fff; margin-top: 18px; margin-bottom: 18px; max-width: 257px; line-height: 1.2;
        margin-left: auto; margin-right: auto; font-family: $comfortaa-font;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}
.align-top{
  vertical-align: top;
}

.game_frame_message{
    top: 2vw;
    width: 79vw;
    height: 4vw;
    max-width: 1536px;
    background-color: rgba(0, 0, 0, 0.45);
    color: #fff;
    text-shadow: 1px 2px 2px #1c4851;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Comfortaa';
    font-size: 2vw;
}

.game_frame_message img{
    float: right;
    right: 0;
    padding-right: 2%;
    position: absolute;
    cursor: pointer;
    width: 4.5vw;
    margin-top: -0.5vw;    
}

.vertical-center {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1000px) {
  .game_frame_message {
     font-size: 24px;
  }

  .game_frame_message img {
    width: 80px;
 }
}

.game_frame_message div{
    text-align: center;
}

#gamepage{
  display: none;
  &.desktop{
    display: block;
  }
}

#footer{
  padding-top: 40px; padding-bottom: 35px; background-image: url("./../images/footer_bg.png"); background-size: cover; 
  min-height: 250px; border-top: 1px solid #6BC7A7;
  .footer_top{
    .flex{
      display: flex;
      .logo{
        flex: 0 0 140px; max-width: 140px; margin-right: 70px;
        div{
          display: block; width: 140px;
          img{
            width: 100%;
          }
        }
      }
      .title{
        text-shadow: 2px 2px 5px #004140; color: #fff; margin-bottom: 10px;
      }
      .app-download{
        margin-right: 45px;
        .app{
          display: flex;
          a{
            margin-right: 7px;
            img{
              height: 35px;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
      .game-download{
        margin-right: 45px;
        .game{
          display: flex;
          a{
            margin-right: 10px;
            img{
              height: 50px;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
      .social-media{
        .items{
          display: flex; align-items: center; padding-top: 10px;
          a{
            margin-right: 15px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &.mobile {
    .footer_top {
      .flex {
        .app-download {
          .app {
            a{
              display: none;
              &.active{
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .footer_bottom{
    font-size: 11px; margin-top: 80px; color: #DCDCDC; font-family: $comfortaa-font; font-weight: 700;
    a{
      color: #DCDCDC;
    }
    .flex{
      display: flex; justify-content: space-between; flex-wrap: wrap;
      .inner_flex{
        display: flex;
      }
      .right-col{
        a{
          margin-right: 25px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .footer_top_logo{
    margin-top: -5px;
  }
}
.cc-window.cc-banner, .cc-window.cc-bottom{
  display: flex; justify-content: center;
  font-family: $comfortaa-font; font-weight: 500; padding-left: 60px; padding-right: 60px;
  background-image: linear-gradient(-180deg, #D9B03E 0%, #A78605 98%);
  .cc-message{
    font-size: 15px; color: #fff; margin-right: 15px;
    line-height: (22px / 15px); max-width: 680px;
  }
  .cc-compliance{
    width: 260px; height: 70px; background-image: url("./../images/bottom_btn.png");
    max-height: 60px !important;
    display: block; background-position: center; margin-top: 10px; flex: 0 0 260px;
    margin-right: 55px;    
    a{
      font-size: 19px; letter-spacing: 0.8px; border: none; padding-top: 15px;
      text-shadow: 1px 1px 5px  #032825; text-transform: uppercase; font-family: $primary-font;
    }
    a:hover{text-decoration: none};
  }
}

.font-size-mobile {
  @media only screen and (orientation:landscape), screen and (min-width : 575px) {
    top: 18px !important;
    font-size: 30px !important;
  }
}
.error_download{
  width: 200px; display: none; margin-left: auto; margin-right: auto;
  img{
    width: 100%; display: block;
  }
  &.active{
    display: block;
  }
}
.center_col{
  margin-top: 5px;
}  

@media only screen and (min-width: 1920px){
}
@media only screen and (max-width: 1599px){
}
@media only screen and (max-width: 1499px){
}
@media only screen and (max-width: 1399px){
}
@media only screen and (max-width: 1199px){
  #footer {
    .footer_top {
      .flex {
        flex-wrap: wrap; justify-content: center;
        .flex_item_4{
          flex: 0 0 33.33%; max-width: 33.33%; margin-right: 0; text-align: center;
        }
        .logo {
          a{
            margin: 0 auto;
          }
        }
        .social-media{
          order: 1;
          .items{
            justify-content: center;
          }
        }
        .app-download{
          order: 3;
          .app{
            justify-content: center;
          }

        }
        .game-download{
          order: 2;
          .game{
            justify-content: center;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px){
  #header_menu_section {
    &.desktop{
      display: block;
    }
    .header_menu_content {
      .inner_content {
        .right_col{
          display: none;
        }
        .center_col{
          display: none;
        }       
      }
    }
  }
  #footer {
    .footer_top {
      .flex {
        .app-download{
          margin-top: 45px;
        }
      }
    }
    .footer_bottom{
      margin-top: 45px;
      .flex{
        text-align: center;
        .left-col, .right-col{
          flex: 0 0 100%; max-width: 100%;
        }
        .left-col{
          margin-bottom: 45px;
        }
        .inner_flex{
          justify-content: center;
        }
      }
    }
  }
  .cc-window.cc-banner, .cc-window.cc-bottom{
    padding-top: 10px; padding-bottom: 10px;
    .cc-message{
      margin-right: 25px;
    }
    .cc-compliance{
      width: 225px; height: 60px; flex: 0 0 225px; background-size: contain;
      a{
        font-size: 16px; padding-top: 16px;
      }
    }
  }
}
@media only screen and (max-width: 767px){
  #errorpage, #gamepage{
    .error_container {
      max-width: 340px;
      .desktop_only{
        display: none;
      }
      .mobile_only{
        display: block;
      }
      .error_content{
        top: 260px; right: 0; left: 0; max-width: 270px; margin: 0 auto;
        .text{
          font-size: 16px; line-height: (22px / 16px); margin-bottom: 0;
        }
        .mobile_only{
          margin-top: 20px;
          .text{

          }
        }
      }
    }
  }
  #footer {
    .footer_top {
      .flex {
        .flex_item_4{
          flex: 0 0 50%; max-width: 50%;
        }
        .game-download{
          flex: 0 0 100%; max-width: 100%; margin-top: 45px;
        }
        .app-download{
          flex: 0 0 100%; max-width: 100%;
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width : 767px){
  #homepage {
    .homepage_content{
      margin-left: 0; margin-right: 0; width: 100%;
    }
    .homepage_content_container{
      margin-left: auto; margin-right: auto;
    }
  }
}
@media only screen and (max-width: 575px){
  .container{
    padding-left: 20px; padding-right: 20px;
  }
  .fullpage{
    background-image: url("./../images/mobile_bg.jpg");
  }
  #homepage {
    .fullpage_bg{
      background-image: url("./../images/home_mobile.jpg");     
    }
    .homepage_content{
      margin-left: auto; max-width: 340px; margin-right: auto; padding-top: 205px;
      &_img{
        width: 260px; margin-bottom: 20px;
      }
      &_container{
        margin-left: 0;
        .desktop_only{
          display: none;
        }
        .mobile_only{
          display: block;
        }
        .homepage_content_text{
          font-size: 19px; margin-bottom: 20px;
        }
      }
      &_login{
        max-width: 260px; margin-left: auto; margin-right: auto; margin-bottom: 25px;
        .text{
          font-size: 18px;
        }
      }
    }
  }
  #errorpage, #gamepage {
    .fullpage_bg{
      background-color: transparent;
    }
  }
  #footer {
    padding-top: 25px; padding-bottom: 40px; background-image: none; position: relative;
    &:after{
      opacity: 0.69; background-image: linear-gradient(-179deg, #0A3837 0%, #010201 72%);
      content: ""; left: 0; top: 0; width: 100%; height: 100%; position: absolute;
    }
    > .container{
      position: relative; z-index: 2;
    }
    .footer_top {
      .flex {
        .flex_item_4{
          flex: 0 0 100%; max-width: 100%;
        }
        .logo {
          a{
            width: 110px;
          }
        }
        .title{
          margin-bottom: 20px;
        }
        .social-media{
          margin-top: 40px;
          .items{
            padding-top: 0;
            a{
              margin-right: 30px; height: 23px;
              img{
                height: 100%;
              }
            }
          }
        }
        .app-download {
          .app{
            flex-direction: column;
            a{
              margin-right: 0; margin-bottom: 15px;
              img{
                height: 45px;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
        .game-download {
          .game {
            a{
              height: 60px; margin-right: 12px;
              img{
                height: 100%;
              }
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .footer_bottom {
      .flex {
        .right-col{
          font-size: 14px;
          a{
            display: block; text-align: center; margin-right: auto; margin-left: auto;
            max-width: 200px; margin-bottom: 30px;
            &:last-child{
              margin-right: auto;
            }
          }
        }
        .left-col{
          order: 2; margin-bottom: 0;
        }
        .inner_flex{
          display: block;
        }
        .copyright{
          margin-bottom: 10px; margin-right: 0;
        }
      }
    }
  }
  .cc-window.cc-banner, .cc-window.cc-bottom{
    display: block; padding-top: 20px; padding-left: 15px; padding-right: 45px;
    .cc-message{
      font-size: 14px; line-height: (22px / 14px);
    }
    .cc-compliance{
      margin-left: -12px; margin-top: 0px;
      a{
        font-size: 15px; padding-top: 15px;
      }
    }
  }
}
@media only screen and (max-width: 320px){
}
@media all and (-ms-high-contrast:none){
}